import { Link } from 'react-router-dom';
import { IContractIncident } from '../../../../../tsTypes/interfaces';
import garbageBag from '../../../../../assets/PeriopsisGarbageBagIcon.png';
import IncidentActions from '../../../../UI/IncidentActions/IncidentActions';
import { MdOutlineSatelliteAlt } from 'react-icons/md';
import getTranslationKey from '../../../../../utils/getTranslationKey';
import Image from '../../../../UI/Image/Image';
import { useTranslation } from 'react-i18next';
import classes from './IncidentListItem.module.scss';

interface IProps {
  incident: IContractIncident;
}

const IncidentListItem = ({ incident }: IProps) => {
  let isDetectedAsClean = false;
  let imageComponent = null;
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  if (incident && 'isCleaned' in incident && incident.isCleaned) {
    isDetectedAsClean = incident.isCleaned;
  }

  if (
    incident &&
    'detectionImage' in incident &&
    incident.detectionImage &&
    Array.isArray(incident.detectionImage) &&
    incident.detectionImage.length > 0
  ) {
    imageComponent = (
      <Image
        imagePath={`${incident.detectionImage[0].path}`}
        alt={`${incident.type}`}
        title={t(`dashboard:${getTranslationKey(incident.type)}`)}
        className={classes.IncidentImage}
        loaderClass={classes.ImageLoader}
        loading='lazy'
      />
    );
  }

  return (
    <Link
      to={`/incident/${incident.gridId}?latestIncident=${incident.gridObjectId}&contractId=${incident.contractId}`}
      className={classes.IncidentListItemContainer}
    >
      <div className={classes.IncidentImageContainer}>{imageComponent}</div>
      {isDetectedAsClean ? (
        <div className={classes.CleanDetectedBySystem}>
          <MdOutlineSatelliteAlt />
        </div>
      ) : null}
      <div className={classes.DataMainContainer}>
        <div className={classes.IncidentDataContainer}>
          <div className={classes.TypeDateMainContainer}>
            <div className={classes.TypeContent}>
              <h3>
                {incident.type.toLocaleLowerCase() ===
                'large site containing dumping'
                  ? 'L.S.C. Dumping'
                  : `${t(`dashboard:${getTranslationKey(incident.type)}`)}`}
              </h3>
            </div>
            <div className={classes.DetectionDateContainer}>
              <p>
                {new Date(incident.detectionDate).toLocaleDateString(language, {
                  year: '2-digit',
                  month: 'long',
                  day: '2-digit',
                })}
              </p>
            </div>
          </div>
          <div className={classes.GarbageIconContainer}>
            <img src={garbageBag} alt='Garbage Bag Icon' />
          </div>
        </div>
        <IncidentActions
          incidentActions={incident.incidentActions}
          isCleaned={incident.isCleaned}
        />
      </div>
    </Link>
  );
};

export default IncidentListItem;
