import {
  IGridPath,
  ISwimmingPoolGridData,
} from '../../../../../tsTypes/interfaces';
import { MdClose } from 'react-icons/md';
import classes from './SwimmingPoolImageModal.module.scss';
import Image from '../../../../UI/Image/Image';
import SimpleLoading from '../../../../UI/SimpleLoading/SimpleLoading';

interface IProps {
  gridData: ISwimmingPoolGridData | null;
  onClose?: () => void;
  isLoading?: boolean;
}

const SwimmingPoolImageModal = ({ gridData, onClose, isLoading }: IProps) => {
  // if (!gridData) {
  //   return null;
  // }

  const images: IGridPath[] = gridData ? gridData.images : [];
  let image: IGridPath | null = null;
  let imagePath = '';

  if (images.length > 0) {
    image = images[0];
    imagePath = image.path;
  }

  let content = (
    <div className={classes.LoadingContainer}>
      <SimpleLoading />
      <p>Loading Swimming Pool Data Please Wait...</p>
    </div>
  );

  if (gridData && !isLoading) {
    content = (
      <>
        <div className={classes.ImageContainer}>
          <Image
            imagePath={imagePath}
            alt='Detected Swimming Pool'
            className={classes.Image}
            loading='lazy'
            title={`Swimming Pool detected at ${gridData.streetAddress} on ${gridData.detectionDate}`}
          />
        </div>
        <div className={classes.DataContainer}>
          <div className={classes.DetectionDate}>
            <p>Detection Date : </p>
            <p>
              {new Date(gridData.detectionDate).toLocaleDateString('en', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              })}
            </p>
          </div>
          <div className={classes.Coordinates}>
            <p>Latitude</p>
            <span>:</span>
            <p> {gridData.coordinates[0]}</p>
            <p>Longitude</p>
            <span>:</span>
            <p>{gridData.coordinates[1]}</p>
          </div>
          <div className={classes.AddressSection}>
            <p>Address : </p>
            <span>{gridData.streetAddress}</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classes.SwimmingPoolImageModalContainer}>
      <div className={classes.ModalContainer}>
        <div className={classes.HeaderSection}>
          <button type='button' onClick={onClose}>
            <MdClose />
          </button>
        </div>
        <div className={classes.ContentContainer}>{content}</div>
      </div>
    </div>
  );
};

export default SwimmingPoolImageModal;
