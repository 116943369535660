// import { useEffect, useState } from 'react';
// import NoImage from '../../../assets/no_image.png';
// import blurImage from '../../../assets/blur_image.png';
// import SkeletonLoading from '../SkeletonLoading/SkeletonLoading';
// import { useLazyGetIncidentImageQuery } from '../../../features/incidents/incidentsSlice';
// import classes from './Image.module.scss';

// interface IProps {
//   imagePath: string;
//   alt: string;
//   width?: string | number;
//   height?: string | number;
//   className?: string;
//   loaderClass?: string;
//   title?: string;
//   onClick?: () => void;
//   loading?: 'lazy' | 'eager';
// }

// const Image = ({
//   imagePath,
//   alt,
//   width,
//   height,
//   className,
//   loaderClass,
//   title,
//   onClick,
//   loading,
// }: IProps) => {
//   const [image, setImage] = useState(blurImage);
//   const [thumbImage, setThumbImage] = useState();

//   const [getIncidentImage, { isLoading, isSuccess }] =
//     useLazyGetIncidentImageQuery();

//   useEffect(() => {
//     const getImage = async (imagePath: string) => {
//       try {
//         const result = await getIncidentImage(imagePath);

//         if (result && result.isSuccess) {
//           if (
//             'data' in result &&
//             result.data &&
//             typeof result.data === 'object' &&
//             'data' in result.data &&
//             result.data.data &&
//             typeof result.data.data === 'object' &&
//             'image' in result.data.data &&
//             result.data.data.image
//           ) {
//             const targetImage = result.data.data.image.url;

//             setThumbImage(result.data.data.image.thumbUrl);
//             setImage(targetImage);
//           }

//           if (
//             'data' in result &&
//             result.data &&
//             typeof result.data === 'object' &&
//             'image' in result.data &&
//             result.data.image
//           ) {
//             const targetImage = result.data.image;

//             setImage(targetImage);
//           }
//         }
//       } catch (error) {
//         console.log(error);
//         setImage(NoImage);
//       }
//     };

//     if (imagePath && alt) {
//       getImage(imagePath);
//     }
//     // eslint-disable-next-line
//   }, [imagePath, alt]);

//   if (isLoading && image !== blurImage) {
//     return (
//       <div
//         className={`${classes.LoaderContainer} ${
//           loaderClass ? loaderClass : ''
//         }`}
//       >
//         <SkeletonLoading width={width || 394} height={height || 470} />
//       </div>
//     );
//   }

//   return (
//     <img
//       src={image}
//       alt={alt}
//       onError={() => {
//         if (!isSuccess) {
//           setImage(NoImage);
//         }
//       }}
//       loading={loading}
//       className={className ? className : ''}
//       title={title}
//       onClick={onClick}
//       width={394}
//       height={470}
//       decoding='async'
//     />
//   );
// };

// export default Image;

import { useEffect, useState } from 'react';
import NoImage from '../../../assets/no_image.png';
import blurImage from '../../../assets/blur_image.png';
import SkeletonLoading from '../SkeletonLoading/SkeletonLoading';
import { useLazyGetIncidentImageQuery } from '../../../features/incidents/incidentsSlice';
import classes from './Image.module.scss';

interface IProps {
  imagePath: string;
  alt: string;
  width?: string | number;
  height?: string | number;
  className?: string;
  loaderClass?: string;
  title?: string;
  onClick?: () => void;
  loading?: 'lazy' | 'eager';
}

const Image = ({
  imagePath,
  alt,
  width = 394,
  height = 470,
  className,
  loaderClass,
  title,
  onClick,
  loading = 'lazy',
}: IProps) => {
  const [image, setImage] = useState<string | undefined>(blurImage);
  const [thumbImage, setThumbImage] = useState<string | undefined>(undefined);
  const [isMainImageLoaded, setIsMainImageLoaded] = useState(false);

  const [getIncidentImage, { isLoading }] = useLazyGetIncidentImageQuery();

  useEffect(() => {
    const getImage = async (imagePath: string) => {
      try {
        const result = await getIncidentImage(imagePath);

        if (result && result.isSuccess) {
          if (
            result.data?.data?.image?.url &&
            result.data.data.image.thumbUrl
          ) {
            setThumbImage(result.data.data.image.thumbUrl);
            setImage(result.data.data.image.url);
          }
        }
      } catch (error) {
        console.error(error);
        setImage(NoImage);
      }
    };

    if (imagePath) {
      getImage(imagePath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagePath]);

  if (isLoading && !thumbImage) {
    return (
      <div
        className={`${classes.LoaderContainer} ${
          loaderClass ? loaderClass : ''
        }`}
      >
        <SkeletonLoading width={width} height={height} />
      </div>
    );
  }

  return (
    <>
      {/* Thumbnail image */}
      {thumbImage && !isMainImageLoaded && (
        <img
          src={thumbImage}
          alt={`${alt} (thumbnail)`}
          loading={loading}
          className={`${classes.ThumbImage} ${
            isMainImageLoaded ? classes.Hidden : ''
          }`}
          width={width}
          height={height}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            objectFit: 'cover',
          }}
        />
      )}
      {/* Full-size image */}
      {image && (
        <img
          src={image}
          alt={alt}
          onLoad={() => setIsMainImageLoaded(true)}
          onError={() => setImage(NoImage)}
          loading={loading}
          className={`${className ? className : ''} ${
            isMainImageLoaded ? '' : classes.Hidden
          }`}
          title={title}
          onClick={onClick}
          width={width}
          height={height}
          decoding='async'
        />
      )}
    </>
  );
};

export default Image;
