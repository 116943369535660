import React from 'react';
import classes from './RadioButton.module.scss';
import { capitalizeAllWords } from '../../../../utils/componentUtilFns';

interface IProps {
  name: string;
  value: string;
  label: string;
  title?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  id?: string;
  type?: string;
  style?: React.CSSProperties;
  addData?: string;
  shouldCapitalize?: boolean;
}

const RadioButton = ({
  name,
  value,
  label,
  onChange,
  title,
  checked,
  id,
  type,
  style,
  addData,
  shouldCapitalize,
}: IProps) => {
  return (
    <div className={classes.RadioButtonContainer}>
      <input
        type={type || 'radio'}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label
        htmlFor={id}
        title={title}
        className={checked ? classes.LabelChecked : ''}
        style={style}
      >
        {shouldCapitalize ? (
          <span>{capitalizeAllWords(label)}</span>
        ) : (
          <span>{label}</span>
        )}
        {addData ? <span>{addData}</span> : null}
      </label>
    </div>
  );
};

export default RadioButton;
