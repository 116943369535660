import { useEffect, useRef, useState } from 'react';
import {
  IGeoJSON,
  ISwimmingPoolGridData,
} from '../../../../tsTypes/interfaces';
import { Coordinates } from '../../../../tsTypes/types';
import {
  Map,
  Source,
  Layer,
  MapRef,
  GeoJSONSource,
  AttributionControl,
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
} from 'react-map-gl';
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from './layers';
import { Maps } from '../../../../tsTypes/enums';
import FlyTo from '../../../UI/FlyTo/FlyTo';
import mapboxgl from 'mapbox-gl';
import { useLazyGetSwimmingPoolGridDataQuery } from '../../../../features/incidents/incidentsSlice';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import SwimmingPoolImageModal from './SwimmingPoolImageModal/SwimmingPoolImageModal';

interface IProps {
  incidents: IGeoJSON | null;
  centerCoords: Coordinates | [];
  height?: number;
  contractId: string;
}

const SwimmingPoolsMap = ({
  incidents,
  centerCoords,
  height,
  contractId,
}: IProps) => {
  const [hasMapRef, setHasMapRef] = useState(false);
  const [gridData, setGridData] = useState<ISwimmingPoolGridData | null>(null);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const mapRef = useRef<MapRef | null>(null);

  const [getGridData] = useLazyGetSwimmingPoolGridDataQuery();

  useEffect(() => {
    if (hasMapRef) {
      const mapReference = mapRef.current;

      if (!mapReference) {
        console.error('mapRef is not initialized');
        return;
      }

      mapReference.on('click', 'unclustered-point', handleMapClick);

      return () => {
        mapReference.off('click', 'unclustered-point', handleMapClick);
      };
    }

    // eslint-disable-next-line
  }, [contractId, hasMapRef]);

  const onClick = (event: mapboxgl.MapLayerMouseEvent) => {
    if (!event) {
      return;
    }

    if (!event.features) {
      return;
    }

    const feature = event.features[0];

    if (!feature) {
      return;
    }

    if (!feature.properties) {
      return;
    }

    if (!mapRef || !mapRef.current) {
      return;
    }

    const clusterId = feature.properties.cluster_id;

    const mapboxSource = mapRef.current.getSource(
      'swimming_pools'
    ) as GeoJSONSource;

    mapboxSource.getClusterLeaves(clusterId, 3, 3, (err, feature) => {
      // console.log(feature);
    });

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) {
        return;
      }

      if (
        mapRef &&
        mapRef.current &&
        feature &&
        feature.geometry &&
        'coordinates' in feature.geometry &&
        feature.geometry.coordinates
      ) {
        mapRef.current.easeTo({
          center: feature.geometry.coordinates as [number, number],
          zoom,
          duration: 500,
        });
      }
    });

    setHasMapRef(true);
  };

  const handleMapClick = async (e: any) => {
    let incidentId = '';
    let coordinates: number[] = [];
    let density = 0;

    if (!e || !e.features) {
      console.error("Couldn't find the event or its features");
      return;
    }

    if (
      e.features[0] &&
      'properties' in e.features[0] &&
      e.features[0].properties &&
      'gridId' in e.features[0].properties
    ) {
      incidentId = e.features[0].properties.gridId;
    }

    if (
      e.features[0] &&
      'properties' in e.features[0] &&
      e.features[0].properties &&
      'coordinates' in e.features[0].properties
    ) {
      const coordinatesString = e.features[0].properties.coordinates;
      coordinates = JSON.parse(coordinatesString);
    }

    if (
      e.features[0] &&
      'properties' in e.features[0] &&
      e.features[0].properties &&
      'density' in e.features[0].properties
    ) {
      density = Number(e.features[0].properties.density);
    }

    setIsLoadingModal(true);

    const response = await getGridData({
      gridId: incidentId,
      contractId,
      latitude: coordinates[0],
      longitude: coordinates[1],
      density,
    }).unwrap();

    setIsLoadingModal(false);

    if (
      response &&
      typeof response === 'object' &&
      'coordinates' in response &&
      response.coordinates &&
      Array.isArray(response.coordinates) &&
      'density' in response &&
      typeof response.density === 'number' &&
      'detectionDate' in response &&
      response.detectionDate
    ) {
      setGridData(response);
      return;
    }

    return toast.error(
      <ToastifyStyledMessage singleMessage='An unexpected error occured while processing the selected swimming pool data. Please try again later.' />
    );
  };

  const closeImageModalHandler = () => {
    setGridData(null);
  };

  if (!incidents) {
    return null;
  }

  return (
    <>
      {gridData || isLoadingModal ? (
        <SwimmingPoolImageModal
          gridData={gridData}
          onClose={closeImageModalHandler}
          isLoading={isLoadingModal}
        />
      ) : null}
      <Map
        initialViewState={{
          latitude: centerCoords[0],
          longitude: centerCoords[1],
          zoom: 6,
        }}
        mapStyle={Maps.navigationMap}
        mapboxAccessToken='pk.eyJ1IjoiYXl0YWNnMjYiLCJhIjoiY2xobThyNmlhMTl2YjNxbnV2YXBreW04eCJ9.sVAdyc54G2c7DPtumtLTDg'
        interactiveLayerIds={[clusterLayer.id!]}
        onClick={onClick}
        style={{
          height: `${height ? `${height}px` : '650px'}`,
          width: '86dvw',
          maxWidth: 'inherit',
          margin: '0 auto',
        }}
        maxZoom={18.5}
        refreshExpiredTiles={true}
        ref={mapRef}
        maxTileCacheSize={500}
        id='cluster_map'
      >
        <Source
          id='swimming_pools'
          type='geojson'
          data={incidents}
          cluster={true}
          clusterMaxZoom={14}
          clusterRadius={60}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
        <AttributionControl
          customAttribution={`Periopsis ${new Date().getFullYear()}`}
          compact={true}
        />
        <FullscreenControl />
        <GeolocateControl
          fitBoundsOptions={{ maxZoom: 30 }}
          showAccuracyCircle={false}
        />

        <NavigationControl visualizePitch={true} />

        {centerCoords !== undefined && centerCoords.length === 2 ? (
          <FlyTo coordinates={centerCoords} zoomLevel={10} />
        ) : null}
      </Map>
    </>
  );
};

export default SwimmingPoolsMap;
