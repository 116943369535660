import { useState, useMemo, useEffect } from 'react';
import { getDistrictMunicipalities } from '../../../../../../../../utils/getMunicipalitiesByDistrict';
import classes from './AreasOfInterest.module.scss';
import RadioButton from '../../../../../../../UI/FormElements/RadioButton/RadioButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllRegistrationFormValues } from '../../../../../../../../features/registration/registrationSlice';
import Letters from '../Letters/Letters';
import { IPredefinedPolygon } from '../../../../../../../../tsTypes/types.model';
import { IAreaOfInterest } from '../../../../../../../../tsTypes/types.components';
import DataRow from '../../../../../../../UI/DataRow/DataRow';
import convertCurrencySignToWord from '../../../../../../../../utils/convertCurrencySignToWord';
import { toast } from 'react-toastify';
import ToastifyStyledMessage from '../../../../../../../UI/ToastifyStyledMessage/ToastifyStyledMessage';
import SelectedPolygonDataList from './SelectedPolygonDataList/SelectedPolygonDataList';
import {
  selectAllUIValues,
  setPolygonListPresentation,
} from '../../../../../../../../features/UI/uiSlice';
import { AppDispatch } from '../../../../../../../../app/store';
import getTotalPolygonPrice from '../../../../../../../../utils/getTotalPolygonPrice';
import SelectedPolygonsList from '../../../../../../../pdfComponents/SelectedPolygonsList/SelectedPolygonsList';
import { usePDF } from '@react-pdf/renderer';
import CenterDiv from '../../../../../../../UI/CenterDiv/CenterDiv';
import SimpleLoading from '../../../../../../../UI/SimpleLoading/SimpleLoading';
import PDFError from '../../../../../../../UI/PDFError/PDFError';
import { FaFilePdf } from 'react-icons/fa';
import AreaOfInterestMaps from '../../../../../../../UI/AreaOfInterestMaps/AreaOfInterestMaps';
import { useTranslation } from 'react-i18next';
import getTranslationKey from '../../../../../../../../utils/getTranslationKey';

interface IProps {
  predefinedPolygons: IPredefinedPolygon[];
  onSelect: (areaOfInterestData: {
    district: string;
    mid: string;
    area: number;
    areaUnit: string;
    polygonName: string;
  }) => void;
  selected: IAreaOfInterest[];
  hideMapHeading?: boolean;
  disabled?: boolean;
}

const AreasOfInterest = ({
  predefinedPolygons,
  onSelect,
  selected,
  hideMapHeading,
  disabled,
}: IProps) => {
  const polygonsByDistrict = useMemo(
    () => getDistrictMunicipalities(predefinedPolygons),
    [predefinedPolygons]
  );

  const { t } = useTranslation();

  const {
    selectedDistrict,
    selectedOrganizationType,
    selectedPackage,
    areasOfInterest,
    selectedCountry,
  } = useSelector(selectAllRegistrationFormValues);
  const { isPolygonListPresentationAtAreaOfInterest } =
    useSelector(selectAllUIValues);
  const dispatch = useDispatch<AppDispatch>();
  const initState =
    selectedDistrict && 'name' in selectedDistrict && selectedDistrict.name
      ? selectedDistrict.name
      : '';

  let totalSelectedArea = 0;
  let totalNumberOfPolygons = 0;
  let areaLimit = 0;
  let areaLimitUnit = 'sqm';
  let priceInSqm = 0;
  let priceUnit = '';
  let countryCode = '';

  const [districtValue, setDistrictValue] = useState('');
  const [selectedLetter, setSelectedLetter] = useState('a');

  const stopDistrictSelection =
    selectedOrganizationType &&
    'title' in selectedOrganizationType &&
    (selectedOrganizationType.title === 'Municipality' ||
      selectedOrganizationType.title === 'Community')
      ? true
      : false;

  if (
    selectedPackage &&
    typeof selectedPackage === 'object' &&
    'areaLimit' in selectedPackage &&
    selectedPackage.areaLimit
  ) {
    areaLimit = selectedPackage.areaLimit;
    areaLimitUnit = selectedPackage.areaLimitUnit;
    priceInSqm = selectedPackage.price;
    priceUnit = selectedPackage.currency;
  }

  const doc = (
    <SelectedPolygonsList
      selectedPolygons={areasOfInterest}
      pricePerSQM={priceInSqm}
      currency={priceUnit}
    />
  );
  const [pdfInstance, updatePDF] = usePDF({ document: doc });
  let pdfComponent: React.ReactNode | null = null;
  const lengthOfAreasOfInterests = areasOfInterest.length;

  useEffect(() => {
    const storedLetter = localStorage.getItem('selected-filter-letter');
    const storedCurrentDistrict = localStorage.getItem('current-district');

    if (storedLetter) {
      setSelectedLetter(storedLetter);
    }

    if (storedCurrentDistrict) {
      setDistrictValue(storedCurrentDistrict);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initState) {
      setDistrictValue(initState);
    }
  }, [initState]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (timer) {
      clearTimeout(timer);
    }

    if (
      lengthOfAreasOfInterests !== undefined &&
      lengthOfAreasOfInterests > 1
    ) {
      let timer = setTimeout(() => {
        updatePDF(doc);
      }, 800);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [lengthOfAreasOfInterests]);

  const districtSelectHandler = (selected: string) => {
    if (!stopDistrictSelection) {
      setDistrictValue(selected);
      setSelectedLetter('a');
      localStorage.setItem('current-district', selected);
    }
  };

  const selectedPolygons =
    polygonsByDistrict && polygonsByDistrict.groupedMunicipalities
      ? polygonsByDistrict.groupedMunicipalities[districtValue]
      : [];

  let letters: string[] = [];

  if (selectedPolygons && selectedPolygons.length > 0) {
    selectedPolygons.forEach((polygon) => {
      const letter = polygon.polygonName.charAt(0);
      if (!letters.includes(letter)) {
        letters.push(letter);
      }
    });
  }

  letters = letters.sort((a, b) => a.localeCompare(b));

  const polygonsByLetter =
    selectedPolygons && selectedPolygons.length > 0
      ? selectedPolygons.filter((polygon) =>
          polygon.polygonName.toLowerCase().startsWith(selectedLetter)
        )
      : [];

  if (selected && Array.isArray(selected) && selected.length > 0) {
    totalSelectedArea = selected.reduce(
      (total, polygon) => total + polygon.area,
      0
    );

    totalNumberOfPolygons = selected.length;
  }

  if (pdfInstance && pdfInstance.loading) {
    pdfComponent = (
      <CenterDiv>
        <SimpleLoading size={0.5} />;
      </CenterDiv>
    );
  }

  if (pdfInstance && pdfInstance.error) {
    pdfComponent = <PDFError />;
  }

  if (
    pdfInstance &&
    pdfInstance.url &&
    areasOfInterest &&
    Array.isArray(areasOfInterest) &&
    areasOfInterest.length > 1
  ) {
    pdfComponent = (
      <a
        href={pdfInstance.url}
        download={`Periopsis_SelectedPolygonsList_${new Date().getTime()}.pdf`}
        onClick={() => updatePDF(doc)}
      >
        <FaFilePdf />
        {t('auth:download_selected_polygons')}
      </a>
    );
  }

  const areaOfInterestSelectHandler = (
    mid: string,
    area: number,
    areaUnit: string,
    nameOfPolygon: string
  ) => {
    if (disabled) {
      return;
    }

    const polygonInSelected = selected.find((polygon) => polygon.mid === mid);
    let isInSelected = false;

    if (polygonInSelected) {
      isInSelected = true;
    }

    if (totalSelectedArea + area > areaLimit && !isInSelected) {
      return toast.error(
        <ToastifyStyledMessage
          singleMessage={t('auth:limit_exceeded_message')}
          heading={t('auth:limit_exceeded')}
        />
      );
    }

    const district = districtValue;

    onSelect({ district, mid, area, areaUnit, polygonName: nameOfPolygon });
  };

  const selectLetterHandler = (letter: string) => {
    if (
      letter &&
      typeof letter === 'string' &&
      letter.length === 1 &&
      !disabled
    ) {
      setSelectedLetter(letter.toLowerCase());
      localStorage.setItem('selected-filter-letter', letter.toLowerCase());
    }
  };

  const polygonListWindowHandler = () => {
    dispatch(setPolygonListPresentation());
  };

  const checkValues = selected.map((item) => item.mid);

  if (selected && isPolygonListPresentationAtAreaOfInterest) {
    return (
      <SelectedPolygonDataList
        selectedPolygons={selected}
        pricePerSQM={priceInSqm}
        currency={priceUnit}
        onClose={polygonListWindowHandler}
      />
    );
  }

  if (
    selectedCountry &&
    typeof selectedCountry === 'object' &&
    'alpha2Code' in selectedCountry &&
    selectedCountry.alpha2Code
  ) {
    countryCode = selectedCountry.alpha2Code;
  }

  return (
    <div className={classes.AreaOfInterestSection}>
      {!hideMapHeading ? <h3>{t('auth:select_areas_of_interest')}</h3> : null}
      <AreaOfInterestMaps
        onSelect={districtSelectHandler}
        selected={districtValue}
        countryCode={countryCode}
      />
      {selected && selected.length > 1 ? (
        <div className={classes.SelectedPolygonsButtonSection}>
          {pdfComponent}
          <button type='button' onClick={polygonListWindowHandler}>
            {t('auth:show_selected_polygons')}
          </button>
        </div>
      ) : null}
      <div className={classes.AreaSelectionSummary}>
        <DataRow
          heading={t('auth:total_number')}
          data={totalNumberOfPolygons.toString()}
          removeSeperator
        />
        <DataRow
          heading={t('auth:total_area')}
          data={`${totalSelectedArea.toFixed(2)} sq km`}
          removeSeperator
        />
        <DataRow
          heading={t('auth:area_limit')}
          data={`${
            areaLimit >= 500000000
              ? t('auth:unlimited')
              : `${areaLimit.toFixed(2)} ${areaLimitUnit}`
          }`}
          removeSeperator
        />
        {priceInSqm > 0 ? (
          <DataRow
            heading={t('auth:total_price')}
            data={`${getTotalPolygonPrice(
              totalSelectedArea,
              priceInSqm
            )} ${convertCurrencySignToWord(
              priceUnit,
              totalSelectedArea * priceInSqm === 1
            )}`}
            removeSeperator
          />
        ) : null}
      </div>
      <Letters
        letters={letters}
        selected={selectedLetter}
        onSelect={selectLetterHandler}
        disabled={disabled}
      />
      <div className={classes.AreaOfInterestOptions}>
        {[...polygonsByLetter]
          .sort((a, b) => a.polygonName.localeCompare(b.polygonName))
          .map((polygon) => (
            <RadioButton
              key={polygon._id}
              label={`${t(
                `translations:${getTranslationKey(polygon.polygonName)}`
              )}`}
              addData={`(${polygon.area} km2)`}
              name={polygon.polygonType || 'area-of-interest'}
              value={polygon.polygonId || ''}
              onChange={() =>
                areaOfInterestSelectHandler(
                  polygon.polygonId,
                  polygon.area,
                  polygon.areaUnit,
                  polygon.polygonName
                )
              }
              checked={checkValues.includes(polygon.polygonId)}
              id={polygon.polygonId}
              type='checkbox'
              style={{ maxWidth: 'max-content', padding: '0.4rem' }}
              shouldCapitalize
            />
          ))}
      </div>
    </div>
  );
};

export default AreasOfInterest;
